<template>
  <client-only>

    <Head>
      <Title>Inicio -</Title>
    </Head>
 
      <Hero :carousel-items="carouselItems" :noticias="noticias" />


    <MisionVision />


    <v-container>
      <div class="bg-section">


        <v-sheet class="grid">
          <NuxtImg v-for="{ id, src, text } in items" :key="id" class="nuxt-img" :src="src" :alt="text" :title="text" />
        </v-sheet>

      </div>
    </v-container>

    <!-- <div class="noticias-wrap">
      <Noticias v-for="section in sections" :key="section.id" :noticias="section" :categories="categories" />
    </div> -->
    <v-container v-if="gallery">
      <Galeria :gallery="gallery" title="Galería de fotos" />
    </v-container>
    <section class="my-12"
      style=" background-image: url('/f.jpg'); background-size: cover; background-position: center;">
      <v-container>
        <GuiaInterrupciones />
      </v-container>
    </section>

    <v-container class="pb-12">
      <PreguntasFrecuentes :preguntas-frecuentes="preguntasFrecuentes" />
    </v-container>


  </client-only>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { collection, query, where, orderBy, limit } from "firebase/firestore";
import { useFirestore, useCollection } from "vuefire";

import { Collections } from "@/constants";
import {
  Banner,
  Category,
  GalleryItem,
  Noticia,
  NoticiaSection,
  FQAs,
} from "@/types";

const db = useFirestore();

// const keyHome = "1CCkto9O4Hc1lHSBezaQ";

// const refSectionsPages = collection(
//   db,
//   `${Collections.Pages}/${keyHome}/${Collections.Sections}`
// );
const galleryRef = collection(db, Collections.Gallery);
const gallery = useCollection(
  query(galleryRef, where("active", "==", true), limit(32))
) as unknown as GalleryItem[];


// const sections = useCollection(
//   query(refSectionsPages, where("active", "==", true))
// ) as unknown as NoticiaSection[];


const refBanners = collection(db, Collections.Banners);
const carouselItems = useCollection(
  query(refBanners, where("active", "==", true), orderBy("dateEnd", "desc"),
    limit(12))
) as unknown as Banner[];

const refNews = collection(db, Collections.News);
const noticias = useCollection(
  query(
    refNews,
    where("active", "==", true),
    where("published", "==", true)
    , orderBy("createdAt", "desc"),
    limit(10)
  )
) as unknown as Noticia[];


// const refCategories = collection(db, Collections.Categories);
// const categories = useCollection(
//   query(refCategories, where("active", "==", true))
// ) as unknown as Category[];
const items = ref<{ id: string; text: string; src: string }[]>([
  {
    id: "enee_generacion",
    src: "/enee-s-02.svg",
    text: "ENEE Generación",
  },
  {
    id: "enee_transmision",
    src: "/enee-s-03.svg",
    text: "ENEE Transmisión",
  },
  {
    id: "enee_distribucion",
    src: "/enee-s-01.svg",
    text: "ENEE Distribución",
  },
]);

const preguntasFrecuentes = ref<FQAs[]>([
  {
    id: 1,
    title: "¿Qué es la reforma energética?",
    description:
      "La reforma energética que abandera el gobierno socialista democrático de la presidenta Xiomara Castro surge con la aprobación de la Ley Especial para Garantizar el Servicio de Energía Eléctrica Como un Bien Público de Seguridad Nacional y un Derecho Humano de Naturaleza Económica y Social, mediante Decreto Ejecutivo 46-2022. Esta ley decreta la energía eléctrica como un bien fundamental para el desarrollo integral de la sociedad y el rescate de la ENEE.",
  },
  {
    id: 2,
    title: "¿Qué es la ENEE?",
    description:
      "Es una empresa pública autónoma responsable de la generación, transmisión, distribución y comercialización del servicio de la energía eléctrica en Honduras. Fue creada mediante Decreto 48 de la Junta Militar de Gobierno el 20 de febrero de 1957. Declarada por el gobierno de la presidenta Xiomara Castro como patrimonio del pueblo hondureño.",
  },
  {
    id: 3,
    title: "¿Cómo puedo aplicar al subsidio a la energía eléctrica?",
    description:
      "Por mandato de la presidenta Xiomara Castro se estableció el subsidio de energía gratis a familias de escasos recursos que consumen menos de 150 KW/h al mes. Este beneficio no aplica a abonados que viven en zonas de alta plusvalía y abonados que tienen más de un contador a su nombre.",
  },
  {
    id: 4,
    title: "¿Cómo puedo contactar a la ENEE?",
    description:
      "Para resolver tus dudas sobre alumbrado público, tarifa eléctrica, instalación y cambio de medidor, entre otros, puedes contactarte con nosotros al número telefónico 2276-4370, desde tu celular marcando el 118, vía WhatsApp al número +504 9440-1515 o consultándonos en nuestras redes sociales. El horario de atención es de lunes a jueves de 8:00 am a 4:00 pm; los viernes de 8:00 am a 3:00 pm.",
  },
]);

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/responsive";

.bg-section {
  @include responsive(mobile) {
    height: 20rem;
    border-radius: 10px !important;
  }

  @include responsive(small) {
    height: 15rem;
  }

  width: 100%;
  background: url("/sobre-nosotros-cover.jpeg") no-repeat center center;
  background-attachment: fixed;
  background-size: cover;
  border-radius: 15px;
  height: 15rem;
}

.grid {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-items: center;
  background: transparent;
  // padding-left: 30px;
  // padding-right: 30px;
  gap: 30px;

  height: 100%;

  @include responsive(mobile) {
    flex-direction: column;
  }

  .nuxt-img {
    border-radius: 10px !important;
    object-fit: cover;
    height: 55px;
  }
}
</style>
