import moment from "moment-timezone";

import { Banner } from "@/types";

export const validSchedule = (banners: Banner[]) => {
  const dateFormat = "YYYY-MM-DD";

  const today = moment().format(dateFormat);

  return banners.filter(
    ({
      dateStart: { seconds: dateStartSeconds },
      dateEnd: { seconds: dateEndSeconds },
    }) => {
      const dateStart = moment(dateStartSeconds * 1000).format(dateFormat);
      const dateEnd = moment(dateEndSeconds * 1000).format(dateFormat);

      return moment(today).isBetween(dateStart, dateEnd);
    }
  );
};
