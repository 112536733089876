<template>
  <v-container class="py-12">
    <v-row>
      <v-col cols="12">
        <v-card flat class="py-3 card mx-auto grid"   style="border-radius: 10px;">
          <span class="pl-6 d-sm-flex justify-center align-center font-weight-bold text-sm-h5 text" >Mantenimientos
            Programados</span>
          <v-btn flat class="btn px-13" link target="_blank" style="border-radius: 10px;"
            href="https://guia-mantenimientos.enee.hn/consulta-circuitos">Saber más</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@import "@/assets/scss/responsive";

.btn {
  background: #59c7d4;
  color: #fff;
  text-transform: capitalize;
  margin-top: 10px;
}

.card {
  @include responsive(mobile) {
    width: 100%;
    height: 12rem;

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }

  @include responsive(small) {
    width: 80%;
    height: 9rem;

    &:hover {
      transform: none;
      box-shadow: none;
    }

  }

  border-radius: 10px;
  // border: 2px solid #59c7d4;
  height: 12rem;

  width: 70%;
  transition: 0.3s ease-in-out;


}

.grid {
  @include responsive(mobile) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  @include responsive(small) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}

.text {
  @include responsive(mobile) {
    font-size: 18px;
    height: 2.5rem;
    line-height: 25px;
    text-align: center;
  }

  line-height: 2rem;
}</style>
