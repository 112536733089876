<template>
  <v-carousel
    hide-delimiter-background
    hide-delimiters
    continuous
    cycle
    v-model="model"
    class="carousel"
  >
    <v-carousel-item
      v-for="{ id, url } in sortedCarouselByPosition"
      :key="id"
      :src="url?.original || '/imageTemplate.svg'"
      :lazy-src="url?.loading || '/imageTemplate.svg'"
      cover
    />
    <template v-slot:prev="{ props }">
        <v-btn size="small" color="#fff" icon="mdi-arrow-left" variant="outlined" @click="props.onClick"></v-btn>

      </template>
      <template v-slot:next="{ props }">
        <v-btn size="small" color="#fff" variant="outlined" icon="mdi-arrow-right"
          @click="props.onClick"></v-btn>
      </template>
    <div class="v-carousel__controls d-none d-sm-flex">
      <div
        v-for="({ id, url }, index) in sortedCarouselByPosition"
        @click="getModelValue(index)"
        :key="id"
        class="items-car"
      >
        <NuxtImg
          preload
          :src="url?.original || '/imageTemplate.svg'"
          height="70"
          width="70"
          quality="10"
          :class="{ 'border-green': model === index, 'border-white': true }"
          :draggable="false"
        />
      </div>
    </div>
  </v-carousel>
  <p
    class="absolute carousel_description pl-6"
    v-if="getCurrentCarouselItemTitle"
  >
    {{ getCurrentCarouselItemTitle }}
  </p>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { Banner } from "@/types";
import { wordLimiter, bannersSort, validSchedule } from "@/helpers";

interface Props {
  carousel: Banner[];
}

const model = ref<number>(0);

const props = defineProps<Props>();

const getModelValue = computed(() => {
  return (index: number) => {
    if (model.value !== index) model.value = index;
  };
});

const sortedCarouselByPosition = computed(() =>
  bannersSort<Banner>(validSchedule(props.carousel))
);

const getCurrentCarouselItemTitle = computed(
  () =>
    // wordLimiter(sortedCarouselByPosition.value[model.value]?.title, 20)
    sortedCarouselByPosition.value[model.value]?.title
);
</script>

<style scoped lang="scss">

@import "@/assets/scss/responsive";
@import "@/assets/scss/global";

.carousel {
  @include responsive(mobile) {
    height: 250px !important;
  }

  border-radius: 10px;
  height: 65vh !important;
  position: relative;

}

.v-carousel__controls {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content:flex-end;
  align-items: flex-end;

  position: absolute;
  right: 0;
  overflow: auto;
  scroll-snap-type: x mandatory;
  gap: 10px;
  margin: 0 0 5rem 0;
  padding-right: 50px;
  padding-left: 250px;
  height: 100px;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  -webkit-mask: linear-gradient(
    90deg,
    transparent 1%,
    #000 5%,
    #000 90%,
    transparent 100%
  );
  mask: linear-gradient(
    90deg,
    transparent 1%,
    #000 2%,
    #000 90%,
    transparent 100%
  );
  .items-car {
    scroll-snap-align: center;
    scroll-snap-stop: always;
    img {
      border-radius: 15px;
      cursor: pointer;
      object-fit: cover;
    }
  }
}

.border-white {
  border: 2px solid #fff;
  filter: grayscale(80%);
  transition: 0.3s ease-in-out;
}

.border-green {
  border: 4px solid #5fd0df !important;
  filter: none !important;
  transform: scale(1.1, 1.1);
  box-shadow: 0px 0px 5px #0000005b;
}

.absolute {
  @include responsive(mobile) {
    position: unset;
  }
  position: absolute;
}

.carousel_description {
  background: linear-gradient(#fff , $bgcolormain );
  // background-color:  #fff ;
  border-radius: 10px 0px 10px 10px;
  min-height: 120px;
  font-size: 20px;
  max-width: 900px;
  padding: 16px 16px 0;
  right: 0;
  bottom: -60px;
  width: 85%;
  @include responsive(mobile) {
    background: transparent;
    border-top-left-radius: 0;
    font-size: 16px;
    font-size: 20px;
    line-height: 25px;
    width: 100%;
  }

  @include responsive(small) {
    bottom: -55px;
  }
}
</style>
