<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" lg="8" class="relative px-0" v-if="carouselLoaded">
        <div class="relative" >
          <Carousel :carousel="carouselItems" />
        </div>
      </v-col>

      <v-col cols="12" md="4" class=" relative">
        <v-card flat color="#fff"  height="100%" style="border-radius: 10px !important;"
          ><v-card-title class="text-decoration-underline text-h6 pl-4"
            >Noticias</v-card-title
          >
          <v-list class="news-list">
            <v-list-item
              v-for="noticia in sortNewsByPublicationDate(noticias)"
              :key="noticia.id"
              @click="openModal(noticia)"
              class="item-list"
            >
              <v-list-item-title class=" text-caption">{{
                dateFormat(noticia.publicationDate.toDate())
              }}</v-list-item-title>

              <p class=" mb-1 mt-1">{{ noticia.title }}</p>

              <span class="text font-weight-medium">{{ wordLimiter(noticia.description) }}</span>
            </v-list-item>
          </v-list>
          <div class="redirect-link">
            <NuxtLink
              class="rounded-0 pl-2 pb-2 text-decoration-underline link"
              to="/noticias"
              flat
              >Ver todo</NuxtLink
            >
          </div>
        </v-card>
      </v-col>
    </v-row>

    <NoticiaModal
      @close-dialog="closeModal"
      :noticia-seleccionada="(noticiaSeleccionada as Noticia)"
      v-model="dialog"
    />
  </v-container>
</template>

<script setup lang="ts">
import { useModal } from "@/composables";
import { dateFormat, wordLimiter, sortNewsByPublicationDate } from "@/helpers";
import { Banner, Noticia } from "@/types";

const { dialog, noticiaSeleccionada, openModal, closeModal } = useModal();

interface Props {
  noticias: Noticia[];
  carouselItems: Banner[];
}

const props = defineProps<Props>();

const carouselLoaded = computed(() => props.carouselItems.length > 0);
</script>

<style scoped lang="scss">
@import "@/assets/scss/responsive";
@import "@/assets/scss/global";

.text {
  font-size: 12px;
  line-height: 1.4;
  margin: 0;
  max-height: 4em;
  overflow: hidden;
  padding: 0 0 20px 0;
  position: relative;
}

.link {
  @include responsive(mobile) {
    text-align: center;
  }

  color: #59c7d4;
  text-transform: uppercase;
}

.relative {
  @include responsive(mobile) {
    position: unset;
  }

  position: relative;
}



.news-list {
  height: 60vh;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-mask: linear-gradient(
    180deg,
    transparent 1%,
    #000 5%,
    #000 90%,
    transparent 100%
  );
  mask: linear-gradient(
    180deg,
    transparent 1%,
    #000 5%,
    #000 90%,
    transparent 100%
  );
  .item-list {
    border-bottom: solid #e3e3e3 1px;
    padding-bottom: 5px;
    padding-top: 10px;
  }
  @include responsive(mobile) {
    height: 60vh;
  }
}

.redirect-link {
  background: linear-gradient(0deg, #ffffff 52%, rgba(0, 0, 0, 0) 100%);
  padding: 25px 0 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;

  align-items: center;
  @include responsive(mobile) {
    justify-content: center;
    margin-left: 0px;
  }
}
</style>
